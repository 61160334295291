<template>
    <div class="percentRingBox">
		<div class="ringBox">
			<span class="percentValue" :style="{color}">{{percent}}%</span>
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100%" viewBox="0 0 100 100" class="ringSvg" style="position: absolute">
				<path d="M50 5 A45 45 0 1 1 5 50 A5 5 0 1 1 15 50 A35 35 0 1 0 50 15 A5 5 0 1 1 50 5 Z" stroke="#ffd03b" stroke-width="1" fill="#2b230e"></path>
				<circle cx="50" cy="50" r="40" stroke-width="10" fill="none" :stroke="color" class="ringCircle" :style="{strokeDashoffset: content2}"></circle>
			</svg>
		</div>
		<div class="textBox">
			<span class="ringName">{{content1}}</span>
			<span class="ringValue">{{events}}</span>
		</div>
	</div>
</template>

<style lang="less" scoped>
.percentRingBox {
	height: 100%;

	.ringBox {
		max-height: 78.44%;
		max-width: 100%;
		margin: auto;
		aspect-ratio: 1/1;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		.percentValue {
			color: #fff;
			font-size: 1.85vh;
			line-height: 1.85vh;
			vertical-align: top;
			font-family: 'titleFont', sans-serif;
			text-shadow: 0px 0px 9px rgba(255,208,59,0.8);
		}

		.ringSvg {
			transform: rotate(-135deg);
			transform-origin: 50% 50%;
		}

		.ringCircle {
			stroke-dasharray: 251.33;
			stroke-linecap: round;
			transform: rotate(-90deg);
			transform-origin: 50% 50%;
			transition: stroke-dashoffset 1.2s;
		}

	}
	.textBox {
		width: 100%;
		height: 21.56%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;

		.ringName {
			color: rgba(255, 255, 255, 0.7);
			font-family: 'pingfangSc', sans-serif;
			font-size: 1.3vh;
			line-height: 1.3vh;
		}
		.ringValue {
			font-family: 'titleFont', sans-serif;
			height: 100%;
			font-size: 1.85vh;
			line-height: 1.85vh;
			background: linear-gradient(180deg, #FFFFFF 42%, #FFD03B 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			margin-left: 4%;
		}
	}
}

</style>

<script>
export default {
	props:['itemName','percent','ringColor','events'],
	data(){
		return {
			content1: '',
			content2: 251.33,
			content4: '',
			color: '',
		}
	},
	mounted(){
		this.initData();
		return false;
	},
	methods: {
		initData(){
			this.content1 = this.itemName;
			let percentage = parseInt(this.percent);
			this.content2 = 251.33 - 188.4975*percentage/100; 
			this.content4 = this.events;
			this.color = this.ringColor;
			return false;
		}
	},
	watch:{
		percent(p){
			let percentage = parseInt(p);
			this.content2 = 251.33 - 188.4975*percentage/100;
			return false;
		}
	}
}
</script>
